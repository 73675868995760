import { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import { sendClick } from 'reaxl-analytics';
import { inventoryDealerDiffClick } from 'reaxl-analytics-handlers';
import { brands, useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import { getClickType, getParentId } from '@/analytics/searchResultsAnalyticsHelpers';

import {
    activeInteractionDuck,
    compareListingsDuck,
    globalConfigsDuck,
    ownersDuck,
    paymentsDuck,
    savedInventoryDuck,
    userDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
    srpPrimeSpotlightDuck,
    srpSpotlightDuck,
} from '@/ducks/srp';
import { vdpActiveInteractionDuck } from '@/ducks/vdp';

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useVdpNavigation from '@/hooks/useVdpNavigation';

import { AlphaModule } from '@/modules';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

import InventoryResultsHandlers from '@/containers/srp/analytics/InventoryResultsHandlers';
import useVehicleHighlightUrgencyDrivers from '@/containers/srp/hooks/useVehicleHighlightUrgencyDrivers';
import InventoryQuickActionsContainer from '@/containers/srp/InventoryQuickActionsContainer';

import getDynamicSpecifications from './dynamicSpecificationUtil';
import saveListing from './saveListing';
import getSpecificationKeys from './specificationUtil';
import vdpUrlBuilder from './vdpUrlBuilder';

const onGetListingContext = (listingCategory) => {
    switch (listingCategory) {
        case 'spotlight':
            return 'sponsored';
        case 'boost':
            return 'recommended';
        case 'peekaboo':
            return 'simplified';
        default:
            return 'default';
    }
};

export default function useInventoryListingProps() {
    const [oneClickListingIds, setOneClickListingIds] = useState([]);

    const {
        compare: [enableCompare],
        enable_360_image: [enable360Image],
        est_payments: [isEstPaymentsEnabled],
        home_services: [isHomeServicesEnabled],
        personalization: [isPersonalizationEnabled, { listings_saved: authenticatedSavesOnly }],
        SRP_DEALERNAME_CLICKABLE: [isDealerNameClickable],
        brand: [, { leads_cc_flag: copySelf, leads_partner_name: partnerName }],
        market_extension: [marketExtensionEnabled],
        my_wallet: [enableMyWallet],
        nds: ndsFeature,
        psx_cta_webcomponents: [, { version = '', branch = 'main' }],
        psx_config: [, { environment = 'prod' }],
        retail_engine: [enableRetailEngine],
    } = useFeatures([
        'compare',
        'enable_360_image',
        'est_payments',
        'home_services',
        'personalization',
        'SRP_DEALERNAME_CLICKABLE',
        'brand',
        'market_extension',
        'my_wallet',
        'nds',
        'psx_cta_webcomponents',
        'psx_config',
        'retail_engine',
    ]);

    const [, {
        disable_payments: isPaymentsDisabled,
    }] = ndsFeature;

    const hasAlphaListings = useSelector(AlphaModule.duck.selectors.hasAlphaListings);
    const dealDetails = useSelector(paymentsDuck.selectors.getTransformedDealDetails, _isEqual);
    const showListingWalletPayment = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'paymentEmphasis'));
    const owners = useSelector(ownersDuck.selectors.getOwners);
    const compareListingIds = useSelector(compareListingsDuck.selectors.getActiveListingIds);
    const peData = useSelector((state) => {
        const allData = PersonalizationEngineModule.duck.selectors.selectPersonalization(state) || {};
        return {
            preferredFuel: _get(allData, 'PrefFuelCat', null),
            recentPreferredFuel: _get(allData, 'RecentPrefFuelCat', null),
            marketingSegments: _get(allData, 'pe_clusters', []),
        };
    }, _isEqual);
    const birf = useSelector((state) => _get(state, 'birf.pageData.page.BIRF', {}));
    const aemHost = useSelector(globalConfigsDuck.selectors.getAemHost);
    const compareToggle = useSelector(userPreferencesDuck.selectors.getCompareToggle);
    const isOneClickOptedIn = useSelector(activeInteractionDuck.selectors.isOneClickOptedIn);
    const leadProfile = useSelector(userDuck.selectors.getLeadProfile);
    const savedListingIds = useSelector(savedInventoryDuck.selectors.getSavedInventoryIds);
    const vehicleHighlightListings = useSelector(srpPrimeSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const filtersValues = useSelector(srpFiltersDuck.selectors.getRequestValues);
    const spotlights = useSelector(srpSpotlightDuck.selectors.getActiveInventory, _isEqual);

    const dispatch = useDispatch();
    const device = useDevice();
    const { isBrand } = useBrand();
    const navigateToVdp = useVdpNavigation();
    const urgencyDriverData = useVehicleHighlightUrgencyDrivers({ listing: vehicleHighlightListings[0] });
    const urgencyDriverType = urgencyDriverData?.type;
    const showPeekabooSpotlight = !!urgencyDriverType;

    const personalPaymentProps = {
        fetcher: PaymentCalculationFetcher,
        paymentsEnabled: enableRetailEngine,
        ndsFeature,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedMakeCode = useMemo(() => _get(filtersValues, 'makeCodeList', '') || _get(filtersValues, 'makeCode', ''), [filtersValues?.makeCodeList, filtersValues?.makeCode]);
    const specificationKeys = useMemo(() => getSpecificationKeys(filtersValues, peData), [filtersValues, peData]);
    const notXS = _get(device, 'greaterThan.xs', false);
    const isFordBranded = isBrand(brands.FORD_BRAND);
    const isShowCompareButton = enableCompare && compareToggle && compareListingIds?.length < 5;
    const { isOneClickOptedIn: leadProfileOptedIn } = leadProfile || {};
    const showEditEmail = isOneClickOptedIn || leadProfileOptedIn;

    const sendDealerDiffCtaClick = useCallback((event, data) => {
        sendClick(inventoryDealerDiffClick, event, data);
    }, []);

    const handleDealerDiffModalToggle = useCallback(() => dispatch(srpActiveInteractionDuck.creators.setKeys({ showDealerDiffModal: true })), [dispatch]);
    const handleListingAnalyticClick = InventoryResultsHandlers.useListingAnalyticClick(sendClick);
    const handleListingNavigationClick = InventoryResultsHandlers.useListingNavigationClick(dispatch, handleListingAnalyticClick, navigateToVdp, vdpUrlBuilder);
    const handleEmailOwnerClick = InventoryResultsHandlers.useEmailOwnerClick(sendClick, showPeekabooSpotlight, copySelf, partnerName, oneClickListingIds, setOneClickListingIds);
    const handleDealerDiffCTAClick = InventoryResultsHandlers.useDealerDiffCTAClick(aemHost, handleDealerDiffModalToggle, sendDealerDiffCtaClick);
    const handleCompareListingsClick = InventoryResultsHandlers.useCompareListingsClick(sendClick, compareListingIds);
    const handleTelMetric = InventoryResultsHandlers.useTelMetric(sendClick, birf);
    const handleEditEmailClick = InventoryResultsHandlers.useEditEmailClick();
    const handleListingItemClick = InventoryResultsHandlers.useListingItemClick(sendClick);
    const handleListingWalletClick = InventoryResultsHandlers.useListingWalletClick(sendClick);
    const handlePaymentCTAClick = InventoryResultsHandlers.usePaymentCTAClick(sendClick);
    const handleSeeVehicleDetailsClick = InventoryResultsHandlers.useSeeVehicleDetailsClick(sendClick, filtersValues);
    const handleCheckDealStatusClick = InventoryResultsHandlers.useCheckDealStatusClick(sendClick, filtersValues);
    const handlePsxListingMakeOfferClick = InventoryResultsHandlers.usePsxListingMakeOfferClick(sendClick, filtersValues, branch, environment, version);

    const getListingSpecificationKeys = useCallback((listing) => (listing.specifications ? specificationKeys.filter((key) => listing.specifications[key]) : []), [specificationKeys]);

    const isActiveDeal = useCallback((listing) => {
        const dealVins = Object.keys(dealDetails).map((dealId) => dealDetails[dealId]?.vehicle?.vin);

        if (dealVins) {
            return dealVins.includes(listing?.vin);
        }

        return false;
    }, [dealDetails]);

    const onGetLazyLoading = useCallback(
        (listingCategory, index) => {
            let lazyLoad = true;
            if ((index === 0 && !hasAlphaListings)
                || (notXS
                    && (listingCategory === 'spotlight' && index < 3)
                    || (listingCategory === 'listings' && (index + Math.min(spotlights.length, 3)) < 3))) {
                lazyLoad = false;
            }
            return {
                lazyLoad,
                loading: lazyLoad ? 'lazy' : 'eager',
            };
        },
        [spotlights.length, notXS, hasAlphaListings],
    );

    const onMapListingToProps = (listing, clickType) => {
        const listingTitle = _get(listing, 'title', '');
        const listingTitleWithNoPkg = listingTitle.substring(0, (listing.title).indexOf(' w/'));
        const listingCTAHref = vdpUrlBuilder({ baseUrl: _get(listing, 'vdpBaseUrl'), clickType });
        const listingOwner = owners[listing.ownerId] || {};
        const payments = ((enableMyWallet && isPaymentsDisabled) || !isEstPaymentsEnabled) ? null : _get(listing, 'pricingDetail.payments');

        return {
            listing: {
                ...listing,
                isHot: listing.isHot,
                title: listingTitleWithNoPkg,
                pricingDetail: {
                    ...listing.pricingDetail,
                    payments,
                },
                owner: {
                    ...listingOwner,
                    dealerDiff: listingOwner.dealerDiff && Object.assign(listingOwner.dealerDiff, { label: listingOwner.dealerDiff?.tagline }),
                    distanceFromSearch: listingOwner.distanceFromSearch && Math.round(listingOwner.distanceFromSearch * 100) / 100 || null,
                    homeServices: isHomeServicesEnabled ? _get(listingOwner, 'homeServices', {}) : null,
                    phone: listing.phone, // add back the phone that we saved earlier in dispatchutils specific to the listing
                    rating: _get(listingOwner, 'rating', {}),
                    shippingLabel: marketExtensionEnabled ? _get(listingOwner, 'shippingLabel', '') : null,
                    website: isDealerNameClickable ? _get(listingOwner, 'website', {}) : null,
                },
                website: {
                    href: listingCTAHref,
                },
            },
            listingCTA: {
                title: listingTitle,
                href: listingCTAHref,
            },
        };
    };

    // TODO: this should use a brand config, not check for isBrand
    const onRenderListingQuickActions = (listing, clickType, parentId) => (isFordBranded ? null : (
        <InventoryQuickActionsContainer
            clickType={clickType}
            listing={listing}
            onEmailClick={handleEmailOwnerClick}
            parentId={parentId}
            phoneClickHandler={handleTelMetric}
        />
    ));

    const getListingProps = (listingCategory, listing, index, paginatedListingIndex) => {
        const { lazyLoad, loading } = onGetLazyLoading(listingCategory, index);
        const listingContext = onGetListingContext(listingCategory);

        if (listing.marketExtension?.imeDmaMatch) {
            listingCategory = 'ime';
        }

        const clickType = getClickType(listingCategory, urgencyDriverData.type);
        const parentId = getParentId(listingCategory, index, urgencyDriverData.type);

        const { listing: listingProps, listingCTA } = onMapListingToProps(listing, clickType);

        const analyticsData = {
            inventoryId: listing.id,
            clickType,
            parentId,
            paginatedListingIndex,
            listing,
            listingCategory,
        };

        const listingOwner = owners[listing.ownerId] || {};
        // TODO: to prevent the need to bind `listing` to `handleListingClick`, etc., InventoryListing should be modified
        // to pass `listing` back to `onClick` handlers the same way it does for `onSaveToggle` handlers.
        const dynamicSpecifications = !selectedMakeCode ? getDynamicSpecifications(filtersValues, listing) : [];

        const onSaveToggle = (event, savedListing, wasSaved) => {
            dispatch(saveListing({
                event,
                listing: {
                    ...savedListing,
                    tier: {
                        name: listingCategory,
                        index,
                        urgencyDriverType,
                    },
                },
                wasSaved,
                sendClick,
            }));
        };

        const onToggleKBBDealerRatingModal = (event) => {
            event.stopPropagation();

            dispatch(vdpActiveInteractionDuck.creators.setKeys({
                showKBBDealerModal: true,
            }));
        };

        return {
            isActiveDeal: isActiveDeal(listing),
            isPhoneNumberCallable: !notXS,
            isSaved: savedListingIds.includes(listing.id),
            isCompared: compareListingIds && compareListingIds.includes(listing.id),
            showEllipses: true,
            showPackages: true,
            showEditEmail,
            showDistance: _get(listingOwner, 'distanceFromSearch', 0) > 0,
            showListingPaymentRibbon: !!enableMyWallet && (!!showListingWalletPayment || listingContext === 'recommended'),
            enableMyWallet: !!enableMyWallet,
            lazyLoad,
            show360: enable360Image && _get(listing, 'fyuseId', false),
            oneClickEmailSent: oneClickListingIds.includes(listing.id),
            paginatedListingIndex,
            key: `listing_${index}`,
            id: String(listing.id),
            parentId,
            className: 'cursor-pointer display-flex flex-column',
            'data-qaid': listingCategory === 'spotlight' ? '' : `cntnr-listing-${listing.id}`, // TODO remove in favor of data-cmp
            clickType,
            loading,
            uiContext: listingContext,
            orientation: 'portrait',
            dynamicSpecifications,
            specificationKeys: getListingSpecificationKeys(listing),
            personalPaymentProps,
            listing: listingProps,
            listingCTA,
            listingQuickActions: onRenderListingQuickActions(listing, clickType, parentId),
            onDealerDiffCTAClick: handleDealerDiffCTAClick.bind(null, listing, clickType, paginatedListingIndex, listingOwner, parentId),
            onSaveToggle: isPersonalizationEnabled || authenticatedSavesOnly ? onSaveToggle : null,
            onCompareClick: isShowCompareButton && handleCompareListingsClick(listing),
            onMyWalletClick: handleListingWalletClick(analyticsData),
            onPaymentCTAClick: handlePaymentCTAClick(analyticsData),
            onOnlinePaperworkClick: handleListingNavigationClick({ ctaType: 'homeServices', ...analyticsData, anchor: 'nativeDealContainer' }),
            onActiveDealDetailsClick: handleSeeVehicleDetailsClick({ listing }),
            onCheckStatusClick: handleCheckDealStatusClick({ listing }),
            onToggleModal: onToggleKBBDealerRatingModal,
            onSelectPsxListing: (event) => handlePsxListingMakeOfferClick(event, analyticsData),
            onCertifiedTileClick: handleListingNavigationClick({ ctaType: 'certifiedTile', ...analyticsData, anchor: 'warrantySection', tileClick: 'true' }),
            onClick: handleListingNavigationClick({ ctaType: 'inventory', ...analyticsData }),
            onContextMenu: handleListingAnalyticClick({ ctaType: 'inventory', ...analyticsData }),
            onPricingDetailClick: handleListingNavigationClick({ ctaType: 'pricing', ...analyticsData, anchor: 'pricingSection' }),
            onProductTileClick: handleListingItemClick({ ctaType: 'product', ...analyticsData }),
            onProductTileLinkClick: handleListingItemClick({ ctaType: 'product', ...analyticsData }),
            handleEditEmail: handleEditEmailClick(listing, parentId, clickType),
            phoneClickHandler: (phoneNumber) => handleTelMetric(listing, phoneNumber, clickType),
            updateListingPayments: (args) => dispatch(paymentsDuck.creators.updateListingPayments(args)),
            paymentsCTA: {
                onClick: handleListingItemClick({ ctaType: 'payment', ...analyticsData }),
            },
            emailOwnerProps: {
                parentId,
                onCTAClick: handleEmailOwnerClick(listing, parentId, clickType),
                context: showPeekabooSpotlight ? 'peekaboo-spv' : '',
                labelKey: 'checkAvailability',
            },
        };
    };

    return getListingProps;
}
