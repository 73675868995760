import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import {
    Button,
    Glyphicon,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import {
    buyOnlineButton,
} from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

import { queryDuck } from '@/ducks';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

const renderBuyOnlineButton = ({
    handleBuyOnlineButtonChange,
    buyOnlineCount,
    isBuyOnlineButtonActive,
    isXs,
}) => {
    const buyOnlineBtnClassNames = clsx({
        'text-right': !isXs,
        'margin-top-2 margin-bottom-2': isXs,
    });

    return (
        <div
            className={buyOnlineBtnClassNames}
        >
            <Button
                bsStyle="default"
                bsSize="small"
                disabled={isBuyOnlineButtonActive}
                onClick={handleBuyOnlineButtonChange}
                aria-label="buy-online-button"
                data-cmp="buy-online-button"
            >
                <Glyphicon
                    glyph="shopping-cart"
                />
                <Text
                    className="margin-horizontal-1"
                >
                    Available to Buy Online
                </Text>
                <Text>
                    {`(${buyOnlineCount})`}
                </Text>
            </Button>
        </div>
    );
};

function BuyOnlineButtonTemp() {
    const {
        srp_buy_online_temp: [, { header_cta_temp: enableBuyOnlineButton }],
    } = useFeatures(['srp_buy_online_temp']);

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const navigateToSrp = useSrpNavigation();
    const dispatch = useDispatch();
    const queryData = useSelector(queryDuck.selectors.getDuckState);
    const hasBuyOnlineExperience = queryData.experience === 'buy-online';

    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const homeServicesOptions = useSelector(srpFiltersDuck.selectors.getHomeServicesOptions);
    const buyOnlineFilter = homeServicesOptions?.options?.filter((option) => option.value === 'BUY_ONLINE')?.[0];
    const buyOnlineCount = buyOnlineFilter?.count || 0;
    const showBuyOnlineBtn = enableBuyOnlineButton && !hasBuyOnlineExperience && (buyOnlineCount !== 0);

    const isBuyOnlineSelected = filtersValues.homeServices
        ? filtersValues?.homeServices?.includes('BUY_ONLINE') : false;

    const handleBuyOnlineButtonChange = (event) => {
        const updatedBuyOnlineFilterValues = {
            ...filtersValues,
        };

        const filter = {
            collapsed: false,
            label: 'Dealer Home Services',
            name: 'homeServices',
            options: homeServicesOptions,
            value: [],
        };

        if (isBuyOnlineSelected) {
            const buyOnlineRemoved = updatedBuyOnlineFilterValues.homeServices ? updatedBuyOnlineFilterValues.homeServices
                .filter((value) => value !== 'BUY_ONLINE') : [];
            Object.assign(updatedBuyOnlineFilterValues, {
                homeServices: buyOnlineRemoved,
            });
            filter.value = [];
        } else {
            const selectedHomeServices = updatedBuyOnlineFilterValues.homeServices || [];
            Object.assign(updatedBuyOnlineFilterValues, {
                homeServices: ['BUY_ONLINE', ...selectedHomeServices],
            });
            filter.value = ['BUY_ONLINE'];
        }

        dispatch(srpFiltersDuck.creators.applyFilterChange(filter?.value, filter, false));

        sendClick(buyOnlineButton, event, { filtersValues });

        navigateToSrp({
            filtersValues: updatedBuyOnlineFilterValues,
            resetPagination: true,
            isNewSearch: true,
        });
    };

    return showBuyOnlineBtn && renderBuyOnlineButton({
        handleBuyOnlineButtonChange,
        buyOnlineCount,
        isBuyOnlineButtonActive: isBuyOnlineSelected,
        isXs,
    });
}

export default BuyOnlineButtonTemp;
