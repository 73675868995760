import React from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import { srpAdsDuck } from '@/ducks/srp';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

const desktopBackFillSize = [728, 90];
const mobileBackFillSize = [300, 250];

// ensure ad sizes are in order from shortest to tallest to make sure min size is smallest height
const desktopSizes = [
    desktopBackFillSize,
    [728, 200],
    'fluid',
];

const kbbMobileSizes = [
    [320, 50],
    mobileBackFillSize,
    [306, 435],
    'fluid',
];

const atcMobileSizes = [
    mobileBackFillSize,
    [306, 435],
    'fluid',
];

//  default props by listing index
const defaultAtcAdProps = {
    0: { id: 'spotlightAd-toptier', position: 'b', sizes: desktopSizes, xsSizes: atcMobileSizes },
    1: { id: 'ad_srp_lstg_1', position: 'c', sizes: desktopSizes, xsSizes: atcMobileSizes },
    2: { id: 'ad_srp_lstg_2', position: 'd', sizes: desktopSizes, xsSizes: atcMobileSizes },
    3: { id: 'ad_srp_lstg_5', position: 'j', sizes: desktopSizes, xsSizes: atcMobileSizes },
    4: { id: 'ad_srp_lstg_6', position: 'k', sizes: desktopSizes, xsSizes: atcMobileSizes },
};

const singleSizeAtcAdProps = {
    0: { id: 'spotlightAd-toptier', position: 'b', sizes: [desktopBackFillSize], xsSizes: [mobileBackFillSize] },
    1: { id: 'ad_srp_lstg_1', position: 'c', sizes: [desktopBackFillSize], xsSizes: [mobileBackFillSize] },
    2: { id: 'ad_srp_lstg_2', position: 'd', sizes: [desktopBackFillSize], xsSizes: [mobileBackFillSize] },
    3: { id: 'ad_srp_lstg_5', position: 'j', sizes: [desktopBackFillSize], xsSizes: [mobileBackFillSize] },
    4: { id: 'ad_srp_lstg_6', position: 'k', sizes: [desktopBackFillSize], xsSizes: [mobileBackFillSize] },
};

const desktopKbbAdProps = {
    0: { id: 'kbbAdsClassfiedsInlineOEM', position: '29', sizes: desktopSizes },
    1: { id: 'kbbAdsClassfiedsInlineOEM2', position: '29', sizes: desktopSizes },
    2: { id: 'kbbAdsClassfiedsInlineOEM3', position: '29', sizes: desktopSizes },
    3: { id: 'kbbAdsClassfiedsInlineOEM4', position: '29', sizes: desktopSizes },
    4: { id: 'kbbAdsClassfiedsInlineOEM5', position: '29', sizes: desktopSizes },
};

const mobileKbbAdProps = {
    0: { id: 'kbbAdsMmaBanner1', position: 'M1', xsSizes: kbbMobileSizes },
    1: { id: 'kbbAdsMmaBanner2', position: 'M1', xsSizes: kbbMobileSizes },
    2: { id: 'kbbAdsMmaBanner3', position: 'M1', xsSizes: kbbMobileSizes },
    3: { id: 'kbbAdsMmaBanner4', position: 'M1', xsSizes: kbbMobileSizes },
    4: { id: 'kbbAdsMmaBanner5', position: 'M1', xsSizes: kbbMobileSizes },
};

function ListingsLinerAds({
    index,
}) {
    const {
        srp_ads_single_size: [isSingleSizeOnly],
        preserve_ad_space: [preserveAdSpace],
    } = useFeatures(['srp_ads_single_size', 'preserve_ad_space']);

    const device = useDevice();
    const notXs = _get(device, 'greaterThan.xs', false);

    const { brand, brands } = useBrand();
    const { KBB_BRAND } = brands;

    const adsLazyLoadingOffset = useSelector(srpAdsDuck.selectors.getAdsLazyLoadingOffset);

    let adProps = isSingleSizeOnly ? singleSizeAtcAdProps[index] : defaultAtcAdProps[index];

    if (KBB_BRAND === brand) {
        adProps = notXs ? desktopKbbAdProps[index] : mobileKbbAdProps[index];
    }

    if (!adProps) {
        return null;
    }

    const sizes = notXs ? adProps.sizes : adProps.xsSizes;
    const adTargeting = {
        pos: adProps.position,
        prd: 'rc12',
    };

    return (
        <DisableAdsFeatureFlag>
            <AtcAdSlot
                className="display-flex flex-column justify-content-center"
                lazyLoading
                lazyLoadingBottomOffset={adsLazyLoadingOffset}
                lazyLoadingTopOffset={adsLazyLoadingOffset}
                preserveSpace={isSingleSizeOnly || preserveAdSpace}
                showPlaceholder
                size={sizes}
                slotId={adProps.id}
                targeting={adTargeting}
            />
        </DisableAdsFeatureFlag>
    );
}

export default ListingsLinerAds;
