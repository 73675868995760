import React from 'react';

import { useSelector } from 'react-redux';

import {
    SponsoredText,
} from 'reaxl';
import {
    InventoryListingPlaceholder,
    InventoryListingV2,
} from 'reaxl-listing';
import { Listing as ListingSchema } from 'reaxl-schema';

import configureListingTiles from '@/utilities/configureListingTiles';
import useInventoryListingProps from '@/utilities/useInventoryListingProps';

import {
    srpSpotlightDuck,
} from '@/ducks/srp';

export function InventorySpotlightContainer({
    baseUrl,
    schemaTemplate,
    schemaType,
    userLocation,
    currentPage,
    listingIndex,
    brand,
    certifiedSponsor,
    isOverrideCarfaxTileEnabled,
    partner,
    removeCarfaxTile,
}) {
    const isSpotlightsLoading = useSelector(srpSpotlightDuck.selectors.getResultLoading);

    const spotlightProps = {
        listingIndex,
        brand,
        certifiedSponsor,
        isOverrideCarfaxTileEnabled,
        partner,
        removeCarfaxTile,
    };

    // Getting the specific spotlight base on index and configure it
    const spotlight = useSelector((state) => srpSpotlightDuck.selectors.getIndividualSpotlight(state, listingIndex));
    const spotlightListing = !!spotlight && configureListingTiles({
        ...spotlightProps,
        tier: 'spotlight',
        items: [spotlight],
    });
    const currentSpotlight = spotlightListing[0];

    const resultsCount = useSelector(srpSpotlightDuck.selectors.getActiveResultsCount);
    const paginatedListingIndex = (listingIndex && (listingIndex + 1) + ((currentPage - 1) * resultsCount)) || undefined;

    const getListingProps = useInventoryListingProps();
    const listingProps = getListingProps('spotlight', currentSpotlight, listingIndex, paginatedListingIndex);
    currentSpotlight.owner = listingProps.listing.owner;

    const spotlightContainer = isSpotlightsLoading ? (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-spotlight"
            key={`spotlight-placeholder-${listingIndex}`}
        />
    ) : (
        <div
            className="col-xs-12 col-sm-4 display-flex"
            key={`spotlight-container-${listingIndex}`}
        >
            <ListingSchema
                key={currentSpotlight.id}
                inventory={currentSpotlight}
                baseUrl={baseUrl}
                schemaTemplate={schemaTemplate}
                schemaType={schemaType}
                searchLocation={userLocation}
            />
            <div
                key={`spotlightSponsoredText-${currentSpotlight.id}`}
                className="display-block me text-right"
                style={{ position: 'absolute', top: -20 }}
            >
                <SponsoredText
                    width="52"
                    height="10"
                />
            </div>
            <InventoryListingV2
                data-cmp="inventorySpotlightListing"
                {...listingProps}
                className="cursor-pointer display-flex flex-column"
            />
        </div>
    );

    return currentSpotlight ? spotlightContainer : null;
}

export default InventorySpotlightContainer;
