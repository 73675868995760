import React, { memo, useCallback, useEffect } from 'react';

import {
    Image,
    SponsoredText,
    Text,

} from 'reaxl';
import { sendImpressions } from 'reaxl-analytics';
import { InventoryListingV2 } from 'reaxl-listing';

import useInventoryListingProps from '@/utilities/useInventoryListingProps';

import { inventoryImpressions } from '@/analytics/srpAnalyticsHandlers';

const eventType = {
    incentive: 'incentives',
    reduced: 'price-reduced',
    newlylisted: 'newly-listed',
    numviewed: 'number-viewed',
};

function PremiumSpotlightContainer({
    premiumSpotlight,
    urgencyDriverData,
    listingIndex,
}) {
    const getListingProps = useInventoryListingProps();

    const sendListingImpression = useCallback(() => {
        sendImpressions({
            name: inventoryImpressions,
            data: {
                cmp: eventType[urgencyDriverData.type],
                selectedItemIds: [premiumSpotlight.id],
            },
        });
    }, [premiumSpotlight.id, urgencyDriverData.type]);

    useEffect(() => {
        if (premiumSpotlight) {
            sendListingImpression();
        }
    }, [premiumSpotlight, premiumSpotlight.id, sendListingImpression]);

    const showUrgencyDriver = urgencyDriverData?.type !== 'default';

    const renderUrgencyDriver = () => showUrgencyDriver && (
        <div
            className="col-12 display-flex align-items-center"
            style={{ minWidth: '70%', height: '10%' }}
        >
            <div className="display-flex align-items-center">
                <Image
                    height={32}
                    width={32}
                    src={urgencyDriverData.imgSrc}
                    alt=""
                />
                <div className="margin-left-3">
                    <Text
                        componentClass="div"
                        color="blue-darker"
                        weight="bold"
                        size={400}
                        className="line-height-sm"
                    >
                        {urgencyDriverData.heading}
                    </Text>
                    <Text
                        color="success"
                        weight="bold"
                        componentClass="div"
                    >
                        {urgencyDriverData.subText}
                    </Text>
                </div>
            </div>
        </div>
    );

    const renderContent = () => (
        <div
            className="padding-horizontal-5 padding-top-3 row"
            style={{ height: '100%', width: '100%' }}
        >
            {renderUrgencyDriver()}
            <div
                className="padding-top-3"
                style={{ minWidth: '70%', height: showUrgencyDriver ? '85%' : '95%' }}
            >
                <SponsoredText />
                <InventoryListingV2
                    uiContext="simplified"
                    style={{ height: '100%' }}
                    {...getListingProps('peekaboo', premiumSpotlight, listingIndex)}
                    className="bg-neutral-secondary cursor-pointer display-flex"
                />
            </div>
        </div>
    );

    return (
        <div className="col-sm-4 col-xs-12 display-flex">
            <div
                data-cmp="PremiumSpotlightContainer"
                key="PremiumSpotlightContainer"
                className="panel panel-default bg-brand-secondary display-flex justify-content-around align-items-center flex-grow padding-bottom-3"
            >
                {renderContent()}
            </div>
        </div>
    );
}

export default memo(PremiumSpotlightContainer);
