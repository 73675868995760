import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import Link from 'next/link';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { removeBasePath } from '@bonnet/next/base-path';
import { useDevice } from '@bonnet/next/device';

import { Breadcrumbs } from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { Breadcrumb as BreadcrumbSchema } from 'reaxl-schema';

import srpBreadcrumbsDuck from '@/ducks/srp/srpBreadcrumbsDuck';

import BuyOnlineButton, { BuyOnlineButtonTemp } from '@/components/BuyOnlineButton';

function SRPBreadcrumbsContainer() {
    const {
        breadcrumbs: [isBreadcrumbsEnabled] = [],
        srp_buy_online_temp: [, { buy_online_breadcrumbs: enableBuyOnlineBreadcrumbsTemp }],
    } = useFeatures(['breadcrumbs', 'srp_buy_online_temp']);

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const breadcrumbs = useSelector(srpBreadcrumbsDuck.selectors.getBreadcrumbs);
    const schemaItems = useSelector(srpBreadcrumbsDuck.selectors.getSchemaItems);

    // Reduce the breadcrumb array to only those that have filter values selected
    // NOTE: label will return undefined if no filter is selected
    const getActiveBreadcrumbs = () => breadcrumbs.reduce((acc, crumb) => {
        if (crumb.label) {
            acc.push(crumb);
        }
        return acc;
    }, []);

    const getActiveSchemaItems = () => schemaItems.reduce((acc, item) => {
        const breadcrumb = breadcrumbs.filter((crumb) => crumb.key === item.key)[0];
        if (breadcrumb.label) {
            acc.push(item);
        }
        return acc;
    }, []);

    // TODO: BONNET NEXT - use of removeBasePath is because we re-enabled all
    // internal URLS to still be created with basePath but next/link will re-append
    const renderBreadcrumb = (breadcrumb, i, arr) => (
        <Link
            {...breadcrumb}
            href={removeBasePath(breadcrumb.href)}
            className={
                clsx(
                    {
                        'text-subdued-lighter': i < arr.length - 1 || i === 0,
                        'text-gray-base': i === arr.length - 1,
                    },
                    'text-size-200 padding-right-2 display-inline-block link-undecorated'
                )
            }
        >
            {breadcrumb.label}
        </Link>
    );

    const renderBuyOnlineButton = enableBuyOnlineBreadcrumbsTemp ? <BuyOnlineButtonTemp /> : <BuyOnlineButton />;

    return !!isBreadcrumbsEnabled
        && (
            <div className="display-flex justify-content-between">
                <Breadcrumbs
                    data-cmp="breadcrumbs"
                    id="breadcrumbs-container"
                    items={getActiveBreadcrumbs(breadcrumbs)}
                    render={renderBreadcrumb}
                >
                    <BreadcrumbSchema config={{
                        crumbs: getActiveSchemaItems(schemaItems),
                    }}
                    />
                </Breadcrumbs>
                {!isXs && renderBuyOnlineButton}
            </div>
        );
}

export default memo(SRPBreadcrumbsContainer, _isEqual);
