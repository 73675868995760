import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import interactionResponse from 'await-interaction-response';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { getMarket } from '@atc/bonnet-reference';

import { DOMAnimation, formattedNumber } from 'atc-js';

import {
    Accordion,
    Button,
    Panel,
    SideBar,
    SideBarHeader,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { featureCodeClick, searchRadiusClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

// Utilities
import filterComponentsMap from '@/utilities/filterComponentsMap';

import { filterClick } from '@/analytics/srpAnalyticsHandlers';

import {
    paymentsDuck,
} from '@/ducks';

import {
    srpActiveInteractionDuck,
    srpContentDuck,
    srpDisableScrollDuck,
    srpFiltersDuck,
    srpMarketExtensionDuck,
    srpResultsDuck,
    srpSimilarListingsDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import FilterSortDropdownContainer from './FilterSortDropdownContainer';
import InventoryClearFilters from './InventoryClearFilters';
import InventoryFiltersChipsContainer from './InventoryFiltersChipsContainer';
import ModelRebadgeModalContainer from './ModelRebadgeModalContainer';
import SaveSearchContainer from './SaveSearchContainer';

// add additional filter to the navigation query if a certain other filter is selected that elicits multiple values
function getExperienceFilters({ enableBuyOnline = false, event = {}, filter = {} }) {
    const multiValueFilters = ['BUY_ONLINE'];
    const extraFilterValues = {};

    if (enableBuyOnline) {
        if (multiValueFilters.includes(event?.target?.value)) {
            if (filter.value && filter.value?.includes('BUY_ONLINE')) {
                extraFilterValues.experience = '';
            } else {
                extraFilterValues.experience = 'buy-online';
            }
        }
    }
    return extraFilterValues;
}

function InventoryFiltersContainer() {
    const device = useDevice();
    const dispatch = useDispatch();
    const isFiltersCollapsed = !!_get(device, 'lessThan.md', false);
    const {
        debounced_filter_navigation: [enableDebouncedFilterNavigation, { wait }],
        filters: [, {
            order: filtersOrder,
        }],
        filter_chips_srp: [enableFilterChips],
        market_extension: [enableMarketExtension],
        srp_buy_online: [enableBuyOnline],
    } = useFeatures([
        'debounced_filter_navigation',
        'filters',
        'filter_chips_srp',
        'market_extension',
        'srp_buy_online',
    ]);

    const isCollapsedFiltersActive = useSelector(srpFiltersDuck.selectors.getFiltersVisible);
    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const hasFiltersOptions = useSelector(srpFiltersDuck.selectors.hasFiltersOptions);
    const resultCount = useSelector(srpResultsDuck.selectors.getResultsCount);

    // All actively expanded accordion panels
    const activeExpansions = useSelector(srpFiltersDuck.selectors.getActiveExpansions);

    const navigateToSrp = useSrpNavigation();

    const handleShowExpansion = (event) => {
        if (!activeExpansions.includes(event)) {
            dispatch(srpFiltersDuck.creators.setActiveExpansion(event));
        }
    };

    const handleHideExpansion = (event) => {
        dispatch(srpFiltersDuck.creators.removeActiveExpansion(event));
    };

    const debouncedNavigateToSrp = _debounce(({ eventId, extraFilterValues = {} }) => navigateToSrp({
        filtersValues: extraFilterValues,
        isNewSearch: true,
        requestId: eventId,
        resetPagination: true,
        scroll: isFiltersCollapsed,
    }), enableDebouncedFilterNavigation ? wait : 0);

    const applyOptionChange = useCallback(async (event, filter, isGrouped) => {
        const eventId = event.target.id || event.target.value;
        window.sessionStorage.setItem('requestId', eventId);
        debouncedNavigateToSrp.cancel();

        // disable the page scrolling to top on route change
        dispatch(srpDisableScrollDuck.creators.setTrue());

        // update redux state with filter change
        dispatch(srpFiltersDuck.creators.applyFilterChange(event, filter, isGrouped));

        dispatch(srpActiveInteractionDuck.creators.setKeys({
            activeModel: '',
        }));

        dispatch(srpSimilarListingsDuck.creators.setActiveResults([]));

        dispatch(srpContentDuck.creators.clearCrawlPaths());

        const extraFilterValues = getExperienceFilters({ enableBuyOnline, event, filter });

        await debouncedNavigateToSrp({ eventId, extraFilterValues });
    }, [debouncedNavigateToSrp, dispatch, enableBuyOnline]);

    const handleZipUpdate = useCallback(async (event, filter) => {
        event.persist();

        const { value = '' } = event.target;
        if (value.length === 5) {
            const response = await getMarket(value);

            if (response.success) {
                dispatch(srpFiltersDuck.creators.removeError({ filter }));
                await applyOptionChange(event, filter);
            } else {
                dispatch(srpFiltersDuck.creators.addError({ errorMessage: 'Enter a valid ZIP Code', filter }));
            }
        } else {
            dispatch(srpFiltersDuck.creators.addError({ errorMessage: 'Enter a valid ZIP Code', filter }));
        }
    }, [applyOptionChange, dispatch]);

    // NOTE: Shared between handleOptionChange and handleClearChip methods
    const sendFilterClick = useCallback((event, filter) => {

        if (filter.name === 'searchRadius' || filter.name === 'featureCodes') {
            return;
        }
        sendClick(filterClick, event, {
            filterName: filter.name,
        });
    }, []);

    const handleOptionChange = useCallback(async (event, filter = {}) => {
        // We need to cache off the value before yielding the thread because otherwise it wil be mutated when we come back
        const { value } = event.target;
        await interactionResponse();
        event.target.value = value;
        sendFilterClick(event, filter);

        let isGrouped = false;

        switch (filter.name) {
            case 'makeCode': {
                isGrouped = true;
                break;
            }

            case 'zip': {
                return handleZipUpdate(event, filter);
            }

            case 'marketExtension': {
                dispatch(srpMarketExtensionDuck.creators.setKey({ value: event.target.value }));
                break;
            }

            case 'maxPrice': {
                dispatch(paymentsDuck.creators.updatePaymentsInfo({ budget: event.target.value }));
                break;
            }

            case 'featureCodes': {
                sendClick(featureCodeClick, event, {
                    filterName: filter.name,
                    featureCodeName: event?.target?.nextSibling?.firstChild?.textContent,
                });
                break;
            }

            case 'searchRadius': {
                sendClick(searchRadiusClick, event, {
                    filterName: filter.name,
                    zipValue: _get(filtersValues, 'zip', false),
                });
                // break omitted on purpose
            }
            // eslint-disable-next-line no-fallthrough
            default:
        }

        applyOptionChange(event, filter, isGrouped);
        return true;
    }, [applyOptionChange, dispatch, filtersValues, handleZipUpdate, sendFilterClick]);

    const handleOptionsClear = useCallback(async (event, filter = {}) => {
        filter.value = [];
        applyOptionChange(event, filter);
    }, [applyOptionChange]);

    const onHideFilters = useCallback(async () => {
        await interactionResponse();
        dispatch(srpFiltersDuck.creators.hideFilters());

        return isFiltersCollapsed && DOMAnimation.jumpToTopOfPage();
    }, [dispatch, isFiltersCollapsed]);

    useEffect(() => {
        if (!enableMarketExtension) {
            dispatch(srpMarketExtensionDuck.creators.setKey({ value: 'off' }));
        }
    }, [dispatch, enableMarketExtension]);

    const handleRemoveMake = useCallback(async (event, filter) => {
        // disable the page scrolling to top on route change
        dispatch(srpDisableScrollDuck.creators.setTrue());
        // update redux state with filter change
        dispatch(srpFiltersDuck.creators.deselectFilter(event, filter));

        dispatch(srpActiveInteractionDuck.creators.setKeys({
            activeModel: '',
        }));

        navigateToSrp({
            isNewSearch: true,
            resetPagination: true,
            scroll: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterProps = {
        onOptionChange: handleOptionChange,
        onOptionsClear: handleOptionsClear,
        handleRemoveMake,
        handleShowExpansion,
    };

    const sideBarHeaderAction = (
        <Button
            bsStyle="primary"
            className="pull-right"
            onClick={onHideFilters}
        >
            See Results
        </Button>
    );

    const sideBarHeader = (
        <SideBarHeader
            title="Filters"
            onHide={onHideFilters}
            action={sideBarHeaderAction}
        />
    );

    const renderFilters = (
        <Accordion
            className="modular-filters"
            data-cmp="filters"
            defaultActiveKey={activeExpansions}
            onOpen={handleShowExpansion}
            onClose={handleHideExpansion}
        >
            { filtersOrder.map((comp) => React.createElement(filterComponentsMap[comp], { ...filterProps, key: comp })) }

        </Accordion>
    );

    // Hidden Filter elements have an absolutely HUGE cost in memory/performance
    // Don't render or compute anything at all unless they are visible.
    // This does remove the animation of the filters side bar at this time
    return (
        <>
            {hasFiltersOptions && (
                <>
                    <SideBar
                        isDocked={false}
                        show={isCollapsedFiltersActive}
                        onHide={onHideFilters}
                        header={sideBarHeader}
                        fullWidth
                    >
                        <Panel className="margin-2">
                            <Panel.Body
                                className="padding-5"
                                data-cmp="cntnr-fltr-selections"
                            >
                                <div
                                    className="display-flex justify-content-between align-items-center"
                                >
                                    <span className="text-size-400 text-bold text-gray-base">Your Search</span>
                                    <span
                                        className="text-size-md-300 text-size-lg-300 text-right text-bold"
                                    >
                                        <SaveSearchContainer key="saveSearchContainer" />
                                    </span>
                                </div>
                                {enableFilterChips && (
                                    <>
                                        <div className="margin-vertical-3">
                                            <InventoryFiltersChipsContainer
                                                selectedFilterValues={filtersValues}
                                            />
                                        </div>
                                        <div className="text-size-md-300 text-size-lg-300 text-right">
                                            {`${formattedNumber({ value: resultCount })} Results`}
                                            <InventoryClearFilters
                                                key="inventoryClearFilters"
                                                collapsed={isFiltersCollapsed}
                                            />
                                        </div>
                                    </>
                                )}
                            </Panel.Body>
                        </Panel>
                        <div className="padding-5">
                            <div className="padding-bottom-2 text-size-400 text-bold text-gray-base">Sort by</div>
                            <FilterSortDropdownContainer />
                        </div>

                        {renderFilters}

                    </SideBar>

                    {!isCollapsedFiltersActive && renderFilters}
                </>
            )}
            <ModelRebadgeModalContainer />
        </>
    );
}

export default memo(InventoryFiltersContainer);
