import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
    Text,
    Toggle,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import {
    buyOnlineToggle,
} from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

const renderBuyOnlineToggle = ({
    handleBuyOnlineToggleChange,
    homeServicesOptions,
    isBuyOnlineToggleActive,
}) => {
    const buyOnlineFilter = homeServicesOptions?.options?.filter((option) => option.value === 'BUY_ONLINE')?.[0];
    const buyOnlineCount = buyOnlineFilter?.count || 0;

    return (
        <div
            className="display-flex flex-nowrap align-items-center padding-top-4 padding-top-sm-0"
            data-cmp="toggle-buy-online"
        >
            <label
                className="display-flex margin-bottom-0 margin-left-auto"
                htmlFor="breadcrumb-and-toggle-header-srp"
            >
                <Toggle
                    checked={isBuyOnlineToggleActive}
                    onChange={handleBuyOnlineToggleChange}
                    aria-label="disabled-buy-online-toggle"
                    data-cmp="buy-online-toggle"
                />
                <Text
                    className="margin-horizontal-1"
                    weight="normal"
                >
                    Available to Buy Online
                </Text>
                <Text
                    color="gray"
                    weight="normal"
                >
                    {`(${buyOnlineCount})`}
                </Text>
            </label>
        </div>
    );
};

function BuyOnlineToggle() {
    const {
        // Optimizely flags
        srp_buy_online: [, {
            header_toggle: enableBuyOnlineToggle,
        }],
    } = useFeatures([
        // Optimizely flags
        'srp_buy_online',
    ]);

    const navigateToSrp = useSrpNavigation();
    const dispatch = useDispatch();

    const homeServicesOptions = useSelector(srpFiltersDuck.selectors.getHomeServicesOptions);
    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);

    const isBuyOnlineSelected = filtersValues.homeServices
        ? filtersValues?.homeServices?.includes('BUY_ONLINE') : false;

    const handleBuyOnlineToggleChange = (event) => {
        const updatedFilterValues = {
            ...filtersValues,
        };

        const filter = {
            collapsed: false,
            label: 'Dealer Home Services',
            name: 'homeServices',
            options: homeServicesOptions,
            value: [],
        };

        if (isBuyOnlineSelected) {
            const buyOnlineRemoved = updatedFilterValues.homeServices ? updatedFilterValues.homeServices
                .filter((value) => value !== 'BUY_ONLINE') : [];
            Object.assign(updatedFilterValues, {
                experience: '',
                homeServices: buyOnlineRemoved,
            });
            filter.value = [];
        } else {
            const selectedHomeServices = updatedFilterValues.homeServices || [];
            Object.assign(updatedFilterValues, {
                experience: 'buy-online',
                homeServices: ['BUY_ONLINE', ...selectedHomeServices],
            });
            filter.value = ['BUY_ONLINE'];
        }

        dispatch(srpFiltersDuck.creators.applyFilterChange(filter?.value, filter, false));

        sendClick(buyOnlineToggle, event, { filtersValues, toggleOn: isBuyOnlineSelected ? 'off' : 'on' });

        navigateToSrp({
            filtersValues: updatedFilterValues,
            resetPagination: true,
        });
    };

    return enableBuyOnlineToggle && renderBuyOnlineToggle({
        handleBuyOnlineToggleChange,
        homeServicesOptions,
        isBuyOnlineToggleActive: isBuyOnlineSelected,
    });
}

export default BuyOnlineToggle;
