import { useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import interactionResponse from 'await-interaction-response';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _set from 'lodash/set';

import { eventBus } from '@atc/cai-event-bus';

import {
    getFirstPartyVistorId,
    getPixallId,
    injectScript,
    waitUntil,
} from 'atc-js';

import { inventoryCertifiedTileClick, inventoryPaymentDetailsClick, listingPaymentCTAClick, myWalletCheckDealStatusCTAClick, ownerEmailSent, psxMakeOfferClick, snapshotPaymentDetailsClick, telMetricsPhoneTextClick } from 'reaxl-analytics-handlers';
import { createEmailData } from 'reaxl-email';

import handleClickableListing from '@/utilities/handleClickableListing';
import { getAbsolutePathWithListingId } from '@/utilities/vdpUrlBuilder';

import { compareListingsDrawerClick, inventoryProductClick } from '@/analytics/inventoryAnalyticsHandlers';
import {
    inventoryClick,
} from '@/analytics/srpAnalyticsHandlers';

import {
    activeInteractionDuck,
    compareListingsDuck,
    paymentsDuck,
    userDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
} from '@/ducks/srp';
import { vdpDealerDiffDuck } from '@/ducks/vdp';

import EmailOwnerFetcher from '@/fetchers/EmailOwnerFetcher';

const InventoryResultsHandlers = {

    useDealerDiffCTAClick(aemHost, handleDealerDiffModalToggle, sendDealerDiffCtaClick) {
        const dispatch = useDispatch();

        return useCallback((listing, clickType, paginatedListingIndex, listingOwner, parentId, event) => {
            event.stopPropagation();
            event.preventDefault();

            const dealerDiffEventResult = {
                prefix: 'text-link::listing::',
                ctaLabel: listingOwner?.dealerDiff?.label,
            };

            const dealerDiffCtaData = { listing, inventoryId: listing.id, clickType, eventResult: dealerDiffEventResult, paginatedListingIndex, par: parentId };

            const query = {
                dealerDiffCmsId: _get(listingOwner, 'dealerDiff.contentId', ''),
                listingType: _capitalize(listing.listingType),
            };

            dispatch(vdpDealerDiffDuck.creators.loadDealerDiff(query, aemHost));

            handleDealerDiffModalToggle();

            sendDealerDiffCtaClick(event, {
                ctaType: _has(listingOwner, 'dealerDiff.tile.src') ? 'cta' : 'link',
                ...dealerDiffCtaData,
            });
        }, [aemHost, dispatch, handleDealerDiffModalToggle, sendDealerDiffCtaClick]);
    },

    useTelMetric(sendClick, birf) {
        return useCallback((listing, phoneNumber, clickType) => {
            const data = {
                birf,
                clickType,
                listing: {
                    ...listing,
                    vdpURL: getAbsolutePathWithListingId(window?.location?.origin, listing?.id),
                    make: listing?.make?.name || listing?.make,
                    model: listing?.model?.name || listing?.model,
                },
                phoneNumber,
            };
            sendClick(telMetricsPhoneTextClick, {}, data);
        }, [birf, sendClick]);
    },

    useCompareListingsClick(sendClick, compareListingIds) {
        const dispatch = useDispatch();
        return useCallback((listing) => async (event) => {
            sendClick(compareListingsDrawerClick, event, {
                inventoryId: listing?.id,
                inheritPageData: false,
                par: 'comp_v_add',
                pixallData: {},
            });

            dispatch(userPreferencesDuck.creators.setKeys({ showDrawer: true }));
            dispatch(compareListingsDuck.creators.addToCompare(listing?.id));
            if (compareListingIds && compareListingIds.includes(listing.id)) {
                dispatch(compareListingsDuck.creators.removeFromCompare(listing?.id));
            }
        }, [sendClick, dispatch, compareListingIds]);
    },

    useEmailOwnerClick(sendClick, showPeekabooSpotlight, copySelf, partnerName, oneClickListingIds, setOneClickListingIds) {

        const dispatch = useDispatch();

        const [cookies = {}] = useCookies(['ATC_ID', 'pxa_ipv4']);

        const leadProfile = useSelector(userDuck.selectors.getLeadProfile);
        const { isOneClickOptedIn: leadProfileOptedIn } = leadProfile || {};

        const userZip = useSelector(userDuck?.selectors?.getZip);
        const isOneClickOptedIn = useSelector(activeInteractionDuck.selectors.isOneClickOptedIn);

        return useCallback((listing, parentId, clickType) => async (event) => {
            if (!isOneClickOptedIn && !leadProfileOptedIn) {
                dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listing.id]));
                dispatch(srpActiveInteractionDuck.creators.setKeys({
                    ownerId: listing?.ownerId ?? '',
                    parentId,
                    showEmailModal: true,
                    clickType,
                    emailCTAContext: showPeekabooSpotlight ? 'peekaboo-spv' : '',
                }));
            } else {
                event?.stopPropagation();
                event?.preventDefault();

                if (leadProfile?.firstName && leadProfile?.lastName && leadProfile?.email) {

                    const { emailData } = createEmailData({
                        clickType,
                        cookies,
                        copySelf,
                        leadProfile,
                        listing,
                        oneClick: true,
                        originPage: 'srp',
                        partnerName,
                        pixallFirstPartyId: getFirstPartyVistorId(),
                        pixallThirdPartyId: getPixallId() || 'nl',
                        userZip,
                    });

                    const emailResult = await EmailOwnerFetcher(emailData);

                    if (emailResult?.email?.status === 'SUCCESS') {
                        sendClick(ownerEmailSent, event, {
                            inventoryId: listing?.id,
                            hasListingType: true,
                            includeWalletData: false,
                            isOneClick: true,
                            par: parentId,
                        });

                        const updatedListingIds = [
                            ...oneClickListingIds,
                            listing.id,
                        ];
                        setOneClickListingIds(updatedListingIds);
                    }
                } else {
                    dispatch(srpActiveInteractionDuck.creators.setKeys({
                        showEmailModal: true,
                        showEditEmailModal: true,
                    }));
                }
            }
        }, [isOneClickOptedIn, leadProfileOptedIn, dispatch, showPeekabooSpotlight, leadProfile, cookies, copySelf, partnerName, userZip, sendClick, oneClickListingIds, setOneClickListingIds]);
    },

    useEditEmailClick() {
        const dispatch = useDispatch();

        return useCallback((listing, parentId, clickType) => async (event) => {
            event.stopPropagation();
            event.preventDefault();

            dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listing.id]));

            dispatch(srpActiveInteractionDuck.creators.setKeys({
                ownerId: listing?.ownerId ?? '',
                parentId,
                showEmailModal: true,
                showEditEmailModal: true,
                clickType,
            }));

        }, [dispatch]);
    },

    useListingAnalyticClick(sendClick) {
        return useCallback(({ inventoryId, ctaType, clickType, parentId, paginatedListingIndex }) => async (event) => {
            let handler = inventoryClick;
            if (ctaType === 'certifiedTile') {
                handler = inventoryCertifiedTileClick;
            }
            const tagData = {
                inventoryId,
                par: parentId,
                clickType,
                paginatedListingIndex,
                pixallData: {
                    eventSource: 'vehicle',
                },
            };
            await sendClick(handler, event, tagData);
        }, [sendClick]);
    },

    useListingNavigationClick(dispatch, handleListingAnalyticClick) {
        return useCallback(({ inventoryId, ctaType, clickType, parentId, paginatedListingIndex }) => async (event) => {
            let nextLink;
            if (!event.target.hasAttribute('data-next-link')) {
                // target and find the child next/link in the listing
                nextLink = event.currentTarget.querySelector('a[data-next-link]');
            }
            const targetValue = _get(event, 'target.value', null);
            await interactionResponse();
            _set(event, 'target.value', targetValue);

            dispatch(srpActiveInteractionDuck.creators.setKeys({
                showSpinningIndicator: true,
            }));

            handleClickableListing(event, () => {
                handleListingAnalyticClick({ inventoryId, ctaType, clickType, parentId, paginatedListingIndex })(event);
                localStorage.setItem('scrollY', window.scrollY);
            }, nextLink);

        }, [dispatch, handleListingAnalyticClick]);
    },

    useListingItemClick(sendClick) {
        return useCallback((listingItemData) => async (event) => {
            // prevent navigating to the vdp for links and CTA's that do not navigate
            event.stopPropagation();

            const { clickType, ctaType, inventoryId, paginatedListingIndex, parentId } = listingItemData;

            // ctaType = "product"
            let handler = inventoryProductClick;
            if (ctaType === 'payment') {
                handler = inventoryPaymentDetailsClick;
            }

            sendClick(handler, event, {
                inventoryId,
                par: parentId,
                clickType,
                paginatedListingIndex,
            });
        }, [sendClick]);
    },

    useListingWalletClick(sendClick) {
        const dispatch = useDispatch();

        return useCallback((listingItemData) => async (event) => {
            event.stopPropagation();

            const { listing, listingCategory } = listingItemData;
            let par;
            switch (listingCategory) {
                case 'spotlight': {
                    par = 'spv_lstg';
                    break;
                }
                case 'supplemental': {
                    par = 'sup_v_lstg';
                    break;
                }
                case 'boost': {
                    par = 'ncb';
                    break;
                }
                default:
                    par = 'v_lstg';
                    break;
            }

            await sendClick(snapshotPaymentDetailsClick, event, {
                par, // Why are we using listingCategory for par?
                ...listingItemData,
            });

            dispatch(paymentsDuck.creators.updateMyWallet({
                listingId: listing.id,
                showPaymentDetailsModal: true,
                listingCategory,
            }));
        }, [dispatch, sendClick]);
    },

    usePaymentCTAClick(sendClick) {
        const dispatch = useDispatch();

        return useCallback((paymentData) => async (event) => {
            event.stopPropagation();

            const { parentId } = paymentData;

            await sendClick(listingPaymentCTAClick, event, {
                par: parentId,
                ...paymentData,
            });

            dispatch(paymentsDuck.creators.updateMyWallet({
                displayModal: true,
            }));
        }, [dispatch, sendClick]);
    },

    useSeeVehicleDetailsClick(sendClick, filtersValues) {
        return useCallback(({ listing = {} }) => (event) => {

            sendClick(myWalletCheckDealStatusCTAClick, event, { inventoryId: listing?.id, filterValues: filtersValues });

        }, [filtersValues, sendClick]);
    },

    useCheckDealStatusClick(sendClick, filtersValues) {
        const dispatch = useDispatch();

        return useCallback(({ listing = {} }) => async (event) => {
            event.stopPropagation();

            await sendClick(myWalletCheckDealStatusCTAClick, event, { inventoryId: listing?.id, filterValues: filtersValues });

            dispatch(paymentsDuck.creators.updateMyWallet({
                displayModal: true,
                selectedPanelName: 'activeDeals',
            }));
        }, [dispatch, filtersValues, sendClick]);
    },

    usePsxListingMakeOfferClick(sendClick, filtersValues, psxBranch, psxEnvironment, psxVersion) {
        const psxWebComponentUrl = `https://static.kbb.com/psx-listing-actions/${[psxBranch, psxEnvironment, psxVersion].filter(Boolean).join('/')}/psx-listing-actions.esm.js`;

        return useCallback(async (event, { listing = {} }) => {
            event.stopPropagation();
            const openMakeOfferEventBus = () => {
                eventBus.emit('psx.listingActions.openMakeOffer', {
                    listingId: listing?.id?.toString(),
                });
                sendClick(psxMakeOfferClick, event, { inventoryId: listing?.id, filterValues: filtersValues });
            };

            if (!document.querySelector(`script[src="${psxWebComponentUrl}"]`)) {
                injectScript(psxWebComponentUrl, {
                    elementAttributes: {
                        type: 'module',
                    },
                    rejectOnError: true,
                }).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Inject PSX webcomponents script failed with detail: ', error?.message);
                });
            }

            // if PSX web-components is not mounted totally, add eventBus to listen `componentDidLoad()` in BaseModal
            // Repo reference: https://ghe.coxautoinc.com/Consumer/psx-listing-actions-web-components/blob/main/app/src/subcomponents/Modal/BaseModal.tsx
            if (!document.querySelector('base-modal')) {
                eventBus.on('psx.listingActions.makeOfferModalIsLoaded', (_) => {
                    // web-components modal has lazy load so try to wait it is totally hydrated on SRP.
                    waitUntil(
                        () => !!document.querySelector('base-modal.hydrated'),
                        openMakeOfferEventBus,
                        500,
                        2000,
                    );
                });
            } else {
                // if PSX web-components is mounted and hydrated, directly send eventBus to open modal.
                openMakeOfferEventBus();
            }
        }, [psxWebComponentUrl, filtersValues, sendClick]);
    },
};

export default InventoryResultsHandlers;
